import React, { useMemo } from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Container from 'components/shared/Container'
import { Heading, HighlightedText, Text } from 'components/shared/Typography'

import Browser from 'components/layout/Flat/Browser'
import CarouselCTA from 'components/layout/Sections/Homepage/CarouselCTA'

import {
  FavouritesColumn,
  InvestmentColumn,
  NumberColumn,
  AreaColumn,
  FloorColumn,
  RoomsColumn,
  AdditionalAreaColumn,
  PriceColumn,
  PricePerM2Column,
  SavingsColumn,
} from 'constants/columns'

import type { CTASlide } from 'components/layout/Sections/Homepage/CarouselCTA/Slide'
import type { PromotionsPageQuery } from 'types/graphql'
import type { Flat } from 'types/flat'

const Section = styled.section`
  padding: 4rem 0 2rem;
  min-height: calc(100vh - 300px);
  ${({ theme }) => theme.media.md.max} {
    padding: 3rem 0 0;
    & > ${Container} > p {
      font-size: 1rem;
    }
  }
`

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 300px;
`

const PromotionsPage: React.FC<PageProps<PromotionsPageQuery>> = ({ data }) => {
  const PAGE = data?.page?.pagePromotion
  const PAGE_SEO = data?.page?.seo
  const FLATS = data.flats.nodes as Flat[]

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const CAROUSEL_CTA_SLIDES: CTASlide[] = useMemo(
    () =>
      PAGE.promotionPageS1Slider?.map((slide) => ({
        image: {
          src: slide?.promotionPageS1SliderElementImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: slide?.promotionPageS1SliderElementImg?.altText!,
        },
        title: slide?.promotionPageS1SliderElementTitle!,
        link: slide?.promotionPageS1SliderElementCta?.url!,
        linkText: slide?.promotionPageS1SliderElementCta?.title!,
      })) || [],
    []
  )

  return (
    <Layout background="white50">
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Promocje' }]} />
      <CarouselCTA slides={CAROUSEL_CTA_SLIDES} />
      <main>
        <Section>
          <Container>
            <Heading
              as="h3"
              size={50}
              sizeDiff={0.7}
              weight={500}
              align="center"
              margin="0.5rem"
              dangerouslySetInnerHTML={{ __html: PAGE.promotionPageS2Title! }}
            />
            <Text
              size={21}
              themecolor="darkgray"
              align="center"
              dangerouslySetInnerHTML={{
                __html: PAGE.promotionPageS2Description!,
              }}
            />
          </Container>
          {FLATS.length > 0 ? (
            <Browser
              flats={FLATS}
              columns={[
                InvestmentColumn,
                NumberColumn,
                AreaColumn,
                FloorColumn,
                RoomsColumn,
                AdditionalAreaColumn,
                // PriceColumn,
                // PricePerM2Column,
                SavingsColumn,
                FavouritesColumn,
              ]}
            />
          ) : (
            <Container>
              <EmptyBox>
                <HighlightedText>Brak mieszkań w promocji</HighlightedText>
              </EmptyBox>
            </Container>
          )}
        </Section>
      </main>
    </Layout>
  )
}

export default PromotionsPage

export const query = graphql`
  query PromotionsPage($locale: String) {
    flats: allFlats(filter: { isPromotion: { eq: true } }) {
      nodes {
        ...FlatsFragment
      }
    }
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/promocje/" }
    ) {
      seo {
        ...WpSEO
      }
      pagePromotion {
        promotionPageS2Title
        promotionPageS2Description
        promotionPageS1Slider {
          promotionPageS1SliderElementTitle
          promotionPageS1SliderElementCta {
            title
            url
          }
          promotionPageS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
